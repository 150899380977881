<template>
    <div class="setPayPwdSuccess">
        <div style="width:980px;height:52px;background:rgba(248,255,208,1);margin:0 auto;padding-top: 17px;padding-left: 27px">
            <span style="width:109px;height:18px;font-size:18px;font-family:'Microsoft YaHei';font-weight:normal;color:rgba(29,29,29,1);">设置支付密码</span>
        </div>
        <div class="updateScuBox">
            <i class="el-icon-circle-check" ></i>
            <p>恭喜，支付密码设置成功！</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "setPayPwdSuccess"
    }
</script>

<style scoped>

</style>